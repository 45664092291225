<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-link to="/"></router-link>

            <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>


<script>
// import HelloWorld from "./components/HelloWorld.vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    name: "App",
    components: {},
    data() {
        return {
            locale: zhCN,
        };
    },
    watch: {
        $route(val) {
            if (val.fullPath) {
                let fullpath = val.fullPath;
                let parmes = "";
                if(fullpath != "/scrm_wap/client/clientDetail"){
                    if (this.isMobile()) {
                        //  console.log('去路由跳转，手机端')
                        if (fullpath.indexOf("/scrm_wap") == -1) {
                            parmes = "/scrm_wap";
                        }
                    } else {
                        //   console.log('去路由跳转，pc端')
                        if (fullpath.indexOf("/scrm_pc") == -1) {
                            parmes = "/scrm_pc";
                        }
                    }
                }else{
                    parmes = fullpath;
                }
                // console.log('去parmes,parmes',parmes)
                if (parmes) {
                    this.$router.push(parmes);
                }
            }
        },
    },
    mounted() {
        this.$message.config({
            top: `100px`,
            duration: 2,
            maxCount: 3,
        });
        // if (this.isMobile()) {
        // 	// alert("手机端");
        // 	this.$router.replace('/scrm_wap');
        // } else {
        // 	// alert("pc端");
        // 	this.$router.replace('/scrm_pc');
        // }
    },
    methods: {
        getUrl(keyUrl, fullpath) {
            console.log('去fullpath',keyUrl,fullpath);
            if (fullpath.indexOf("scrm_wap") != -1) {
                if (fullpath.split("scrm_wap")[1]) {
                    keyUrl = keyUrl + fullpath.split("scrm_wap")[1];
                }
                console.log("去手机端", keyUrl);
            } else if (fullpath.indexOf("scrm_pc") != -1){
                if (fullpath.split("scrm_pc")[1]) {
                    keyUrl = keyUrl + fullpath.split("scrm_pc")[1];
                }
                console.log("去pc端", keyUrl);
            }
            else{
                keyUrl = "scrm_pc/home"
            }
            console.log('去',keyUrl)
            return keyUrl;
        },
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 16px;
    overflow: auto;
    height: 100vh;
}
.global_head_title{
    text-align: left;
    font-weight: bold;
}
.redS{color: red;}
</style>
