<template>
    <div class="titleHistory">
            <img @click="$router.back()" class="img" src="../assets/imgs/wap/return.png" alt="">
        <h1>{{ message }}</h1>  
    </div>
</template>

<script>
export default {
    name: 'MyComponent',
    props: {
        // 声明 prop  
        propMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            message: this.propMessage // 或者你可以在这里定义自己的数据  
        };
    },
    // 其他选项，如 methods, computed, watch 等  
};
</script>

<style scoped>
.titleHistory{
    height: 80px;
    background: transparent;
    overflow: hidden;
    padding: 0;
    position: relative;
}
.img{
    float: left;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%) ;
}
h1{
    text-align: center;
    font-size: 18px;
    line-height: 80px;
    color: #fff;
}
</style>