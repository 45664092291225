const utils = {
    numberToWan (number) {
      let result = 0
      if (number >= 10000) {
        const newNumber = (number / 10000).toFixed(2)
        result = newNumber + '万'
      } else {
        result = number
      }
      return result
    }
  }
  
  export default utils